
import IntegrationsEnum from "~/helpers/integrationsEnum";

export default {
	async asyncData({ store, params, route }) {
		let page = null;
		if (params.pathMatch === "") {
			// is this the home route?
			page = { ...store.getters.getHomePage };
		} else if (route.name !== "all") {
			page = {
				...store.getters.getPageByHandle(route.name),
			};
		} else {
			if (!params.pathMatch) {
				return;
			}
			page = {
				...store.getters.getPageByHandle(
					params.pathMatch.slice(
						params.pathMatch.lastIndexOf("/") + 1
					)
				),
			};
		}
		page.blocks = page?.blocks || [];
		if (typeof page.blocks === "string") {
			page.blocks = JSON.parse(page.blocks);
		}
		const themes = {
			color: store.getters.getThemeByKey("color"),
			font: store.getters.getThemeByKey("font"),
		};
		const siteDomains = store?.state?.site?.siteDomains || [];
		const site = store.state?.site;
		return {
			page,
			themes,
			siteDomains,
			site,
		};
	},
	head() {
		let cssText = [this.themes?.color, this.themes?.font].join("\n");
		const prod = this.$nuxt.context.base === "/";
		const pageTitle = this.page?.seoTitle || this.site?.title;
		const pageMetas = [
			{
				property: "og:url",
				content: `${
					prod && this.siteDomains?.length > 0
						? this.siteDomains[0]?.url
						: "http://localhost:3000"
				}${this.$route?.path || ""}`,
			},
			{
				property: "og:type",
				content: "website",
			},
		];

		if (pageTitle) {
			pageMetas.push({
				property: "og:title",
				content: pageTitle,
			});
		}

		if (this.page?.seoDescription) {
			pageMetas.push({
				name: "description",
				hid: "description",
				content: this.page.seoDescription,
			});
			pageMetas.push({
				property: "og:description",
				content: this.page.seoDescription,
			});
		} else {
			pageMetas.push({
				name: "description",
				hid: "description",
				content: this.site?.seoDescription || "",
			});
		}

		if (this.page?.head?.ogImage) {
			pageMetas.push({
				property: "og:image",
				content: this.page.head.ogImage,
			});
			pageMetas.push({
				property: "og:image:width",
				content: "1200",
			});
			pageMetas.push({
				property: "og:image:height",
				content: "630",
			});
		}

		const pageLinks = [];

		if (this.page?.head?.favicon) {
			pageLinks.push({
				rel: "icon",
				type: "image/x-icon",
				href: this.page.head.favicon,
			});
		}

		return {
			title: pageTitle,
			style: [
				{
					cssText,
					type: "text/css",
				},
			],
			link: pageLinks,
			meta: pageMetas,
		};
	},
	mounted() {
		const oneTrustIntegration = this.$config?.siteData?.integrations?.find(
			(i) => i.integration.id === IntegrationsEnum.OneTrust
		);

		if (oneTrustIntegration) {
			window?.dataLayer?.push({
				"onetrust-data-domain-script": oneTrustIntegration.domainId,
			});
		}
	},
};
